body {
  color: #333;
}

.centeredFullPageContainer {
  display: flex;

  justify-content: center;
  align-items: center;

  min-height: 100vh;
}