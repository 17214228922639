@import '../../assets/mixins/breakpoints.scss';

#SessionPage {
    padding-top: 62px;
    min-height: 100vh;

    display: flex;

    z-index: 10;

    @include breakpoint(mobileonly){
        flex-direction: column;
    }

    div.loaderContainer {
        flex: 1;

        display: flex;

        justify-content: center;
        align-items: center;
    }

    div#container.container {
        position: relative;
        flex: 1;

        @include breakpoint(mobileonly){
            min-height: 70vh;
            overflow-x: hidden;
        }

        header#header {
            z-index: 100;
        }

        header#header > div {
            background-color: #fefefe;
            color: #333;
        }

        div#navSessionInfo,
        button#navChatButton {
            display: none;
        }

        .pointer.nickname {
            z-index: 100;
        }

        div#layout.bounds,
        .OT_root,
        .OT_widget-container,
        .streamComponent,
        video {
            @include breakpoint(mobileonly){
                width: 100vw !important;
            }
        }
    }

    div.sidebar {
        width: 250px;

        @include breakpoint(mobileonly){
            width: 100%;
        }

        padding: 1em;
    }
}